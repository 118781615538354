import api from "./config/Axios";
import qs from "qs";

export default class ArquivosService {
  static async getArquivosStorage(
    token,
    tipo,
    page = 0,
    qtdArquivos = 1000,
    filtro
  ) {
    // return api({
    //   method: 'get',
    //   url: `/arquivos/storage/`,
    //   headers: {
    //     "Authorization": `Bearer ${token}`,
    //     "Tipo-Arquivo": tipo,
    //   },
    // });
    console.log({
      Authorization: `Bearer ${token}`,
      ...filtro,
    });
    return api({
      method: "get",
      url: `/arquivos/descritores/${tipo}/${page}/${qtdArquivos}`,
      headers: {
        Authorization: `Bearer ${token}`,
        ...filtro,
      },
    });
  }

  static async getArquivosBanco(token, tipo) {
    return api({
      method: "get",
      url: `/arquivos/banco/${tipo}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async getOcupacaoStorage(token) {
    return api({
      method: "get",
      url: `/arquivos/ocupacao/storage/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async getOcupacaoBanco(token) {
    return api({
      method: "get",
      url: `/arquivos/ocupacao/banco/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async downloadArquivoStorage(token, nome_arquivo, tipo, onProgress) {
    return api({
      method: "get",
      url: `/arquivos/`,
      responseType: "blob",
      onDownloadProgress: onProgress,
      headers: {
        Authorization: `Bearer ${token}`,
        "Tipo-Arquivo": tipo,
        "nome-arquivo": nome_arquivo,
      },
    });
  }

  static async apagaArquivoStorageTipo(token, tipo) {
    return api({
      method: "delete",
      url: `/arquivos/diretorio/${tipo}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async apagaArquivoStorageSelecionado(token, tipo, objs) {
    return api({
      method: "delete",
      url: `/arquivos/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Tipo-Arquivo": tipo,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      data: qs.stringify(objs),
    });
  }

  static async importarArquivosCertificado(
    token,
    pedidos,
    ehProcurador,
    ehCpf,
    numDoc,
    nome_certificado
  ) {
    return api({
      method: "post",
      url: `/arquivos/importar/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ehProcurador: ehProcurador, // boolean
        ehCpf: ehCpf, // boolean
        num_doc: numDoc, // num cpf ou cnpj
        nome_certificado: nome_certificado,
        pedidos: pedidos, // array like [{"tipo": "ECF", "dt_ini": "01/01/2017", "dt_fim": "01/01/2021"}]
      },
    });
  }

  static async importarArquivosArquivei(dt_ini, dt_fim, nome_credencial, tipo, token, cnpjs) {
    return api({
      method: "post",
      url: `/integracoes/arquivei/importar/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        dt_ini: dt_ini,
        dt_fim: dt_fim,
        nome_credencial: nome_credencial,
        tipo: tipo,
        cnpjs: cnpjs,
      },
    });
  }

  static async importarArquivosThomson(dt_ini, dt_fim, nome_credencial, tipo, token, cnpj, ie) {
    return api({
      method: "post",
      url: `/integracoes/thomson/importar/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        dt_ini: dt_ini,
        dt_fim: dt_fim,
        nome_credencial: nome_credencial,
        tipo: tipo,
        cnpj: cnpj,
        ie: ie
      },
    });
  }
}
